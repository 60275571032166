import $ from 'jquery';
import imagesLoaded from 'imagesloaded';
var article = getUrlParameter('article');

if (article) {
  var articleSlug = $('#issue-article-' + article).attr('slug');
  var urllink = window.location.pathname;
  window.history.replaceState({}, document.title, urllink + '#' + articleSlug);
  var articlePos = $('#issue-article-' + article).offset().top;
  $('html,body').animate({
    scrollTop: articlePos
  });
}

function getUrlParameter(sParam) {
  var sPageURL = decodeURIComponent(window.location.search.substring(1)),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
}

;

if ($('.li-submit').length) {
  $('.li-submit').on('click', function (e) {
    e.preventDefault();
    $('.li-email').removeClass('error');
    var email = $('.li-email').val();
    console.log(email.length);

    if (email.length) {
      signup_user(email);
    } else {
      $('.li-email').addClass('error');
    }
  });
}

function signup_user(email) {
  var searchQuery = window.location.search;
  var ref;
  var signupdata = {
    'action': 'do_ajax',
    'fn': 'sign_up_user',
    'email': email
  };

  if (searchQuery !== '' && searchQuery.indexOf('ref=') > -1) {
    ref = searchQuery.split('=')[1];
    signupdata['ref'] = ref;
  }

  $.ajax({
    url: ajaxurl,
    method: 'post',
    type: 'json',
    data: signupdata
  }).done(function (response) {
    response = $.parseJSON(response);
    console.log(response);
    $('.li-signup-text').html('<div class="response-text">' + response.response + '</div>');
    $('.li-email').val('');
    gtag('event', 'sign_up', {
      'email': email,
      'status': response.status
    });
  });
}

function isEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

if ($('.issue.new').length) {
  loadIssueImages();
}

if ($('#gif-background').length) {
  imagesLoaded($('#gif-background'), {
    background: true
  }, function () {
    $('#content').addClass('loaded');
  });
}

var loadingIssues = false;

if ($('.more-link').length) {
  $('.more-link').on('click', function (e) {
    e.preventDefault();
    var $link = $(this);
    var linkScroll = $($link).offset().top;
    var paged = $($link).attr('next');

    if (!loadingIssues) {
      // Loading issues. Don't touch anything!
      loadingIssues = true;
      $($link).css('color', 'black');
      $('.loading').css('opacity', 1);
      var loadingText = '';
      var loading = setInterval(function () {
        loadingText += '.';
        $('.loading span').html(loadingText);

        if (loadingText === '...') {
          loadingText = '';
        }
      }, 400);
      console.log(paged);
      $.ajax({
        url: ajaxurl,
        method: 'post',
        type: 'json',
        data: {
          'action': 'do_ajax',
          'fn': 'get_more_posts',
          'paged': paged
        }
      }).done(function (response) {
        response = $.parseJSON(response);

        if (response.posts) {
          $('#issues-archive').append(response.posts);
          $($link).css('color', 'white');
          $('.loading').css('opacity', 0);
          clearInterval(loading);
          $('html,body').animate({
            scrollTop: linkScroll
          });
          loadIssueImages();
        }

        if (response.more) {
          $($link).attr('next', parseInt(paged) + 1);
          loadingIssues = false;
        } else {
          loadingIssues = true;
          $($link).hide();
        }
      });
    }
  });
}

function loadIssueImages() {
  $('.issue.new').each(function () {
    var $issue = this;
    imagesLoaded($($issue), {
      background: true
    }, function () {
      $('.issue-image', $issue).removeClass('hidden');
      $($issue).removeClass('new');
    });
  });
} // if($('#issues-archive').length) {
//   $('#issues-archive').masonry({
//     // options
//     itemSelector: '.issue',
//     columnWidth: 200
//   });
// }